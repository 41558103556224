import "../scss/main.scss";

const isMobile = (/Mobi/i.test(navigator.userAgent));

// Fonction de sélection accès Piétons/Skieurs

const buttonSkiers = document.getElementById("buttonSkiers");
const buttonWalkers = document.getElementById("buttonWalkers");
const blockSkiers = document.getElementById("pointSkiers");
const blockWalkers = document.getElementById("pointWalkers");

buttonSkiers.addEventListener("click", function (e) {

    activeButton(buttonSkiers, buttonWalkers, blockSkiers, blockWalkers);

});

buttonWalkers.addEventListener("click", function (e) {

    activeButton(buttonWalkers, buttonSkiers, blockWalkers, blockSkiers);

});

function activeButton(button1, button2, block1, block2) {

    button1.classList.remove("buttonAccessOff");
    button1.classList.add("buttonAccessOn");
    button2.classList.remove("buttonAccessOn");
    button2.classList.add("buttonAccessOff");
    block1.classList.remove("none");
    block2.classList.add("none");

};

/*
    Composant Galerie
*/

const zoom = document.getElementById("zoom");
const zoom2 = document.getElementById("zoom2");
const zoom3 = document.getElementById("zoom3");
const popin = document.getElementById("popin");
var x = window.matchMedia("(min-width: 1200px)")

zoom.addEventListener("click", function (e) {
    if (x.matches) {
        popin.classList.remove("none");
    }
})

zoom2.addEventListener("click", function (e) {
    if (x.matches) {
        popin.classList.remove("none");
    }
})

zoom3.addEventListener("click", function (e) {
    if (x.matches) {
        popin.classList.remove("none");
    }
})

const btnClose = document.getElementById("btnClose");

btnClose.addEventListener("click", function (e) {
    popin.classList.add("none");
});

const photosInOverlay = document.querySelectorAll(".overlay-img");
let currentPhoto = 0;

const setCurrentPhoto = (photoIndex) => {
    photosInOverlay.forEach((element, index) => {
        if(index == photoIndex){
            element.classList.remove("none");
        }else{
            element.classList.add("none");
        }
    })
    currentPhoto = photoIndex;
}

const btnNext = document.getElementById("btnNext");
btnNext.addEventListener("click", function (e) {
    let nextPhoto = currentPhoto + 1;
    if(nextPhoto > photosInOverlay.length - 1) nextPhoto = 0;
    console.log(nextPhoto)
    setCurrentPhoto(nextPhoto);
})

const btnPrevious = document.getElementById("btnPrevious");
btnPrevious.addEventListener("click", function (e) {
    let nextPhoto = currentPhoto - 1;
    if(nextPhoto < 0) nextPhoto = photosInOverlay.length - 1;
    setCurrentPhoto(nextPhoto);
})

// Toggle Header when scrolling
const navbar = document.getElementById("header");
window.addEventListener("scroll", function (e) {
    if (window.scrollY > 0) {
        navbar.classList.add("scroll")
    } else {
        navbar.classList.remove("scroll");
    }
})

// Toggle Header menu on mobile
const menuBtn = document.getElementById("mobile-menu-toggle");
menuBtn.addEventListener("click", () => {
    navbar.classList.toggle("toggled")
})

// Close when tapping outside of the menu
navbar.addEventListener("click", (e) => {
    if(e.currentTarget === e.target){
        navbar.classList.remove("toggled")
    }
})

// Close when tapping a link
const headerMenuLinks = document.querySelectorAll(".navbar-links a");
headerMenuLinks.forEach(link => {
    link.addEventListener("click", () => {
        navbar.classList.remove("toggled")
    })
});

// Hijack all links with an anchor and adjust the scroll animation
const anchorLinks = Array.from(document.querySelectorAll("a")).filter(link => {
    return link.href.includes("#")
})
anchorLinks.forEach(link => {
    link.addEventListener("click", (event) => {
        event.preventDefault()
        const destinationId = "#" + link.href.split("#")[1]
        const destination = document.querySelector(destinationId)
        let scrollY = destination.offsetTop - 128
        if(isMobile) scrollY = destination.offsetTop - 80
        window.scrollTo(0, scrollY)
    })
})